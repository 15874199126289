import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '..';
import { createSurveyObjProp } from '../../components/Home/Types';
import { apiServiceInstanceQuestionBuilder } from '../apiUtils';
import {
  batchObjProp,
  deleteProjectObjProp,
  projectObjProp,
  searchObjProp,
  usersObjProp,
  adduserObjProp,
  edituserObjProp,
  deleteuserObjProp,
  formStatusObjProp,
  batchthemeObjProp,
  URLgenObjProp,
  daysObjProp,
  PanelObjProp,
  sendpanelObjProp,
  setThemeObjProp,
  deleteThemeObjProp,
  putThemeObjProp,
  TemplateObj,
  postExternalPanelObjProp,
  putExternalPanelObjProp,
  deleteExternalPanelObjProp,
  updateExternalPanelObjProp,
  downloadQuestionsObjProp,
} from '../Reducers/Types';

export const getAllProjects = createAsyncThunk(
  'getAllProjects',
  async (projectObj: projectObjProp) => {
    const res = await apiServiceInstanceQuestionBuilder.post(
      '/project/getall',
      projectObj,
    );
    return res;
  },
);

export const deleteProject = createAsyncThunk(
  'deleteProject',
  async (deleteProjectObj: deleteProjectObjProp) => {
    const res = await apiServiceInstanceQuestionBuilder.delete('/project/', {
      data: deleteProjectObj,
    });
    return res;
  },
);

export const getAllSurveys = createAsyncThunk(
  'getAllSurveys',
  async (batchObj: batchObjProp) => {
    const res = await apiServiceInstanceQuestionBuilder.post(
      '/batch/getall',
      batchObj,
    );
    return res;
  },
);

export const getAllThemes = createAsyncThunk('getAllThemes', async () => {
  const res = await apiServiceInstanceQuestionBuilder.post('/theme/get');
  return res;
});

export const getCurrentTheme = createAsyncThunk(
  'getCurrentTheme',
  async (obj: PanelObjProp) => {
    const res = await apiServiceInstanceQuestionBuilder.post(
      '/batch/getsingle',
      obj,
    );
    return res;
  },
);

export const putBatch = createAsyncThunk(
  'putBatch',
  async (batchObj: batchthemeObjProp) => {
    const res = await apiServiceInstanceQuestionBuilder.put(
      '/batch/',
      batchObj,
    );
    return res;
  },
);

export const putTheme = async (themeObj: putThemeObjProp) => {
  const res = await apiServiceInstanceQuestionBuilder.put('/theme/', themeObj);
  return res;
};

export const downloadQuestions = async (
  downloadQuestionsObj: downloadQuestionsObjProp,
) => {
  const res = await apiServiceInstanceQuestionBuilder.post(
    '/question/download',
    downloadQuestionsObj,
  );
  return res;
};

export const setdays = createAsyncThunk(
  'setdays',
  async (daysObj: daysObjProp) => {
    const res = await apiServiceInstanceQuestionBuilder.post(
      '/responsetoken/generate',
      { ...daysObj, isPreview: false },
    );
    return res;
  },
);

export const getURL = createAsyncThunk(
  'getURL',
  async (urlObj: URLgenObjProp) => {
    const res = await apiServiceInstanceQuestionBuilder.post(
      '/responsetoken/get',
      urlObj,
    );
    return res;
  },
);

export const getPanel = async (PanelObj: PanelObjProp) => {
  const res = await apiServiceInstanceQuestionBuilder.post(
    '/panel/get',
    PanelObj,
  );
  return res;
};

export const postPanel = async (panelObj: sendpanelObjProp) => {
  const res = await apiServiceInstanceQuestionBuilder.post('/panel/', panelObj);
  return res;
};

export const postTheme = createAsyncThunk(
  'postTheme',
  async (panelObj: setThemeObjProp) => {
    const res = await apiServiceInstanceQuestionBuilder.post(
      '/theme/',
      panelObj,
    );
    return res;
  },
);

export const getSearchSurveys = createAsyncThunk(
  'getSearchSurveys',
  async (searchObj: searchObjProp) => {
    const res = await apiServiceInstanceQuestionBuilder.post(
      '/project/search',
      searchObj,
    );
    return res;
  },
);

export const getAllUsers = createAsyncThunk(
  'getAllUsers',
  async (usersObj: usersObjProp) => {
    const res = await apiServiceInstanceQuestionBuilder.post(
      '/user/getall',
      usersObj,
    );
    return res.data;
  },
);

export const addSurvey = createAsyncThunk(
  'addSurvey/getAllSurveys',
  async (addSurveyObj: createSurveyObjProp, { dispatch, getState }) => {
    const state = getState() as RootState;
    await apiServiceInstanceQuestionBuilder.post('/batch/', addSurveyObj);

    const { payload } = await dispatch(
      getAllProjects({
        projectId: 0,
      }),
    );

    const { projectTitle, projectId, researchType, researchSubType } =
      addSurveyObj;
    const currentSurveyProjectId = !!projectTitle
      ? payload?.data?.value.find((p) => p.title === projectTitle)?.id || 0
      : projectId;

    await dispatch(
      getAllSurveys({
        projectId: currentSurveyProjectId,
        desc: true,
        limit: 10,
        orderBy: 'id',
        offset: 0,
      }),
    );

    return { title: addSurveyObj.batchTitle, researchType, researchSubType };
  },
);

export const addUser = async (adduserObj: adduserObjProp) => {
  const res = await apiServiceInstanceQuestionBuilder.post(
    '/user/',
    adduserObj,
  );
  return res;
};

export const editUser = async (edituserObj: edituserObjProp) => {
  const res = await apiServiceInstanceQuestionBuilder.put(
    '/user/',
    edituserObj,
  );
  return res;
};

export const deleteUser = async (deleteuserObj: deleteuserObjProp) => {
  const res = await apiServiceInstanceQuestionBuilder.delete('/user/', {
    data: deleteuserObj,
  });
  return res;
};

export const deleteTheme = async (deleteThemeObj: deleteThemeObjProp) => {
  const res = await apiServiceInstanceQuestionBuilder.delete('/theme/', {
    data: deleteThemeObj,
  });
  return res;
};

type deleteSurveyType = {
  batchId: number;
  currentPaginationProp: batchObjProp;
  deleteType?: string;
};

export const deleteSurvey = createAsyncThunk(
  'deleteSurvey/getAllSurvey',
  async (data: deleteSurveyType, { dispatch }) => {
    const { batchId, currentPaginationProp, deleteType } = data;
    const res = await apiServiceInstanceQuestionBuilder.delete('/batch/', {
      data: { batchId, deleteType },
    });

    dispatch(getAllSurveys(currentPaginationProp));
    return res;
  },
);

type duplicateSurveyType = {
  batchId: number;
  currentPaginationProp: batchObjProp;
};

export const duplicateSurvey = createAsyncThunk(
  'duplicateSurvey/getAllSurvey',
  async (data: duplicateSurveyType, { dispatch }) => {
    const { batchId, currentPaginationProp } = data;
    const res = await apiServiceInstanceQuestionBuilder.post(
      '/batch/duplicate',
      { batchId },
    );
    dispatch(getAllSurveys(currentPaginationProp));
    return res;
  },
);

export const getPermissions = createAsyncThunk(
  'getPermissions',
  async (batchId: usersObjProp) => {
    const res = await apiServiceInstanceQuestionBuilder.post(
      '/batch/getpermission',
      batchId,
    );
    return res.data.value;
  },
);

export const saveTemplate = async (saveTemplateObj: TemplateObj) => {
  const res = await apiServiceInstanceQuestionBuilder.post(
    '/batch/templates',
    saveTemplateObj,
  );
  return res;
};

export const getExternalPanel = createAsyncThunk(
  'getExternalPanel',
  async (externalPanelObj: URLgenObjProp) => {
    const res = await apiServiceInstanceQuestionBuilder.post(
      '/externalpanel/get',
      externalPanelObj,
    );
    return res;
  },
);

export const postExternalPanel = async (
  postExternalPanelObj: postExternalPanelObjProp,
) => {
  const res = await apiServiceInstanceQuestionBuilder.post(
    '/externalpanel/',
    postExternalPanelObj,
  );
  return res;
};

export const getSelectedExternalPanel = createAsyncThunk(
  'getSelectedExternalPanel',
  async (getSelectedExternalPanelObj: URLgenObjProp) => {
    const res = await apiServiceInstanceQuestionBuilder.post(
      '/batch/getsingle',
      getSelectedExternalPanelObj,
    );
    return res;
  },
);

export const putExternalPanel = async (
  putExternalPanelObj: putExternalPanelObjProp,
) => {
  const res = await apiServiceInstanceQuestionBuilder.put(
    '/batch/',
    putExternalPanelObj,
  );
  return res;
};

export const deleteExternalPanel = async (
  deleteExternalPanelObj: deleteExternalPanelObjProp,
) => {
  const res = await apiServiceInstanceQuestionBuilder.delete(
    '/externalpanel/',
    {
      data: deleteExternalPanelObj,
    },
  );
  return res;
};

export const updateExternalPanel = async (
  updateExternalPanelObj: updateExternalPanelObjProp,
) => {
  const res = await apiServiceInstanceQuestionBuilder.put(
    '/externalpanel/',
    updateExternalPanelObj,
  );
  return res;
};

export const getStudyPermissions = createAsyncThunk(
  'getStudyPermissions',
  async (obj: any) => {
    const res = await apiServiceInstanceQuestionBuilder.post(
      'user/getStudyPermission',
      obj,
    );
    return res;
  },
);

export const getClientUsage = createAsyncThunk(
  'getClientUsage',
  async (obj: any) => {
    const res = await apiServiceInstanceQuestionBuilder.post(
      '/clientsManagement/',
      obj,
    );
    return res;
  },
);

export const getMyUsage = createAsyncThunk('getMyUsage', async (obj: any) => {
  const res = await apiServiceInstanceQuestionBuilder.post('/usage/', obj);
  return res;
});

export const fileReader = async (payload: { batchId: string; url: string }) => {
  const res = await apiServiceInstanceQuestionBuilder.post(
    '/file/reader',
    payload,
  );
  return res;
};

export const addCredit = async (obj: any) => {
  const res = await apiServiceInstanceQuestionBuilder.post(
    '/credit_management/',
    obj,
  );
  return res;
};

export const updateCredit = async (obj: any) => {
  const res = await apiServiceInstanceQuestionBuilder.put(
    '/credit_management/',
    obj,
  );
  return res;
};

export const getArchivedData = async (obj: any) => {
  const res = await apiServiceInstanceQuestionBuilder.post(
    '/batch/getArchiveData',
  );
  return res;
};

export const restoreArchivedStudy = async (obj: any) => {
  const res = await apiServiceInstanceQuestionBuilder.put('/batch/', obj);
  return res;
};
