import { Menu } from 'antd';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import SettingsIcon from '@/components/Survey/SettingsIcon';
import useProfileInfo from '@/hooks/useProfileInfo';
import { AppDispatch } from '@/store/index';
import { UsersSlice } from '@/store/Reducers/loginReducer';
import { BillingIcon, LogOutIcon } from '../Icons';
import { MyUsageLogo } from '@/components/Sidebar/icon';
import { dynamicRouteUsage } from '@/components/Sidebar/Tabs';
import { logoutUser } from '@/store/asyncActions/auth';
import { ArchiveIcon } from '@/components/Dashboard/Icons';

const index = () => {
  const router = useRouter();
  const dispatch = useDispatch<AppDispatch>();
  const { userColor, username, email, profilePicUrl } = useProfileInfo();

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const profilePicStyle = {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    ...(!!profilePicUrl
      ? { backgroundImage: `url(${profilePicUrl})` }
      : { backgroundColor: userColor }),
  };

  const items = [
    {
      // label: <div className="bg-black"> hey</div>,
      key: 'submenu',
      children: [
        {
          type: 'group',
          label: (
            <div className="flex justify-start items-center text-primary-black border-b pb-2 border-b-indigo-100">
              <div
                className="rounded-full text-white capitalize w-[32px] h-[32px] text-sm text-center flex justify-center items-center"
                style={profilePicStyle}
              >
                {!profilePicUrl && username.charAt(0)}
              </div>
              <div className="flex flex-col ml-4 justify-center items-start">
                <div className="heading-4 capitalize">{username}</div>
                <div className="tags-font">{email}</div>
              </div>
            </div>
          ),
          children: [
            {
              label: (
                <div
                  className="flex w-[240px] ml-[-20px] items-center justify-start cursor-pointer hover:text-link-blue hover:bg-dash-bgcolor p-2"
                  onClick={() => router.push('/settings?tabId=settings')}
                >
                  <SettingsIcon color="currentColor" />
                  <span className="heading-4 capitalize ml-2">Settings</span>
                </div>
              ),
              key: 'settings',
            },
            {
              label: (
                <div
                  className="flex w-[240px] ml-[-20px] items-center justify-start cursor-pointer hover:text-link-blue hover:bg-dash-bgcolor p-2"
                  onClick={() => router.push('/settings?tabId=archive')}
                >
                  <ArchiveIcon color="currentColor" />
                  <span className="heading-4 capitalize ml-2">Archive</span>
                </div>
              ),
              key: 'archive',
            },
            {
              label: (
                <div
                  className="flex w-[240px] ml-[-20px] items-center justify-start cursor-pointer hover:text-link-blue hover:bg-dash-bgcolor p-2"
                  onClick={() => router.push(dynamicRouteUsage('my-usage'))}
                >
                  <MyUsageLogo color="currentColor" size={20} />
                  <span className="heading-4 capitalize ml-2">Usage</span>
                </div>
              ),
              key: 'usage',
            },
            // {
            //   label:
            //     <div className="flex w-[240px] ml-[-20px] justify-start items-center cursor-pointer hover:text-link-blue hover:bg-dash-bgcolor p-2 border-b border-b-indigo-100" onClick={() => router.push('/billing')}>
            //       <BillingIcon />
            //       <span className="heading-4 capitalize ml-2">Billing</span>
            //     </div>,
            //   key: 'billings'
            // }
          ],
        },
        {
          type: 'group',
          label: <div className="border-b border-b-indigo-100"></div>,
          children: [
            {
              label: (
                <div
                  className="flex w-[240px] ml-[-20px] mb-2 justify-start items-center cursor-pointer hover:text-link-blue hover:bg-dash-bgcolor p-2"
                  onClick={handleLogout}
                >
                  <LogOutIcon />
                  <span className="heading-4 capitalize ml-2">Log Out</span>
                </div>
              ),
              key: 'logout',
            },
          ],
        },
      ],
    },
  ];

  return (
    <Menu
      items={items}
      expandIcon={
        <div style={{ color: 'white', fontSize: '24px', marginRight: '7px' }}>
          {!profilePicUrl && username.toUpperCase().charAt(0)}
        </div>
      }
      style={{
        borderRadius: '100%',
        marginBottom: '25px',
        border: 'none',
        ...profilePicStyle,
      }}
    ></Menu>
  );
};

export default index;
